import Vue from 'vue';

import VueObserveVisibility from 'vue-observe-visibility';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VTooltip from 'v-tooltip';
import VueMoment from 'vue-moment';
import 'bootstrap';
import Axios from 'axios';
import axios from 'axios';

import {format, parseISO} from 'date-fns';

// Import the Auth0 configuration
import {domain, clientId} from '../auth_config.json';

// Import the plugin here
import {Auth0Plugin} from './auth';


import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/scss/app.scss';

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    const to = appState && appState.targetUrl ? appState.targetUrl : {name: 'login'};
    router.push(to);
  },
});

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
Vue.use(VueSvgInlinePlugin);

import {BootstrapVue} from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import VCalendar from 'v-calendar';
// Use v-calendar & vc-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueToast, {position: 'bottom-right', duration: 5000});
Vue.use(VTooltip);
Vue.use(VueMoment);
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;

Axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.config.url !== '/xr-request-token' && (error.response.status === 401)) {
        localStorage.removeItem('token');
        localStorage.removeItem('expired_in');
        delete axios.defaults.headers.common['Authorization'];
        window.location.href = '/';
      }

      return Promise.reject(error);
    },
);
const token = localStorage.getItem('token');

// let API_URL = process.env.VUE_APP_API_URL;

// if (!API_URL) {
//   API_URL = 'https://services.svcore.io/api/';
//   //API_URL = 'https://api.' + window.location.host + '/api/';
// }

//Runtime env extraction
const configEnv = {
  // API_URL: process.env.VUE_APP_API_URL || 'https://api.' + window.location.host + '/api/'
  API_URL: process.env.VUE_APP_API_URL || 'https://services.svcore.io/api/'
};

if (!configEnv.API_URL) {
  Vue.prototype.$http.defaults.baseURL = configEnv.API_URL;
} else {
  fetch('/config.json')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((config) => {
      if (config.VUE_APP_API_URL) {
        configEnv.API_URL = config.VUE_APP_API_URL;
        Vue.prototype.$http.defaults.baseURL = configEnv.API_URL;
        (window as any).CONFIG = config;
      }
      
    })
    .catch((error) => {
      console.error('Error fetching config.json:', error);
      Vue.prototype.$http.defaults.baseURL = configEnv.API_URL;
    });
}


(window as any).CONFIG = '';


Vue.prototype.$http.defaults.baseURL = configEnv.API_URL;
Vue.prototype.$http.defaults.headers.post['Accept'] = 'application/json';
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Vue.prototype.$http.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
}

let handleOutsideClick;
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const {handler, exclude} = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl && excludedEl.contains(e.target);
        }
      });
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },
  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

// Allows for the console to be turned on and off
const logger = function() {
  let oldConsoleLog = null;
  const pub:any = {};

  pub.enable = function enableLogger() {
    if (oldConsoleLog == null) {
      return;
    }

    window['console']['log'] = oldConsoleLog;
  };

  pub.disable = function disableLogger() {
    oldConsoleLog = console.log;
    window['console']['log'] = function() {};
  };

  return pub;
}();

// window.DEBUG makes DEBUG a global variable used throughout the code stack
window.DEBUG = {
  aInternal: 10,
  aListener: function(val) {},
  set LOG(val:boolean) {
    this.aInternal = val;
    this.aListener(val);
  },
  get LOG():boolean {
    return this.aInternal;
  },
  registerListener: function(listener) {
    this.aListener = listener;
  },
};

window.DEBUG.registerListener(function(val) { // When the variable is set / get (changed)
  if (val) {
    logger.enable();
  } else {
    logger.disable();
  }
});

if (process.env.NODE_ENV == 'production') { // In prod env
  logger.disable(); // Mute console log
}

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('titleFormat', function(value) {
  const regex = /-_/g;
  return value.toString().replace(regex, ' ').toLowerCase().replace(/^\w|\s\w/g, (letter) => {
    return letter.toUpperCase();
  });
});

Vue.filter('dateFormat', function(value) {
  if (value && typeof value === 'string') {
    return format(parseISO(value), 'dd-MM-yyyy');
  } else {
    return format(value, 'dd-MM-yyyy');
  }
  // return value;
});

Vue.filter('HoursMinsSecs', function(value) {
  const measuredTime = new Date(null);
  measuredTime.setSeconds(value);
  return measuredTime.toISOString().substr(11, 8);
});

/** Function takes string argument in "HH:MM:SS" as well as "MM:SS" or "SS" format.
 * Returns HH:MM:SS format
 * @param {String} str the initial format of the time string
 * @return {String} Final format HH:MM:SS format
 */
Vue.filter('timeInHoursMinsSeconds', function(str) {
  // get time in seconds
  const splitTime = str.split(':');
  let s = 0;
  let m = 1;

  while (splitTime.length > 0) {
    s += m * parseInt(splitTime.pop(), 10);
    m *= 60;
  }
  if (s >= 3600) {
    const hoursNo = Math.floor(s / 3600);
    const hours = (hoursNo).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    const minutesNo = Math.floor(s % 3600 / 60);
    const minutes = (minutesNo).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    const secondsNo = Math.floor(s % 3600 % 60);
    const seconds = (secondsNo).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    return hours + ':' + minutes + ':' + seconds;
  }
  return str;
}),

/** Function takes string argument in string or date format.
 * Returns HH:mm formatted string
 * @param {string | Date} date the initial format of the time string
 * @return {string} Final format HH:mm format
 */
Vue.filter('timeFormat', function(date: Date | string) {
  const dateParsed: Date = typeof date === 'string' ? parseISO(date) : date;
  return format(dateParsed, 'HH:mm');
}),

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
