<template>
  <header class="page-header">
    <nav class="navbar navbar-expand-md">
      <ul class="container-fluid navbar-nav row mx-0 justify-content-md-center">
        <li class="nav-item col-12 col-md-7 text-center mx-0">
          <router-link
            class="navbar-brand"
            :to="{name: 'dashboard', params: {'reloadRequired': true}}"
          >
            <img
              id="test"
              ref="brand"
              :src="brand_logo"
            >
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script>
import * as urlParse from 'url-parse';

export default {
  data() {
    return {
      innerPreviousRoute: {
        name: '',
        params: {},
      },
      brand_logo: '',

    };
  },
  computed: {
    isBranded() {
      return this.$route.params.client ? true : false;
    },
  },
  mounted() {

    let API_URL = process.env.VUE_APP_API_URL
    if(!API_URL){
      API_URL = 'https://services.svcore.io/api/';
      //API_URL = 'https://api.' + window.location.host + '/api/'
    }

    const parsed = urlParse(process.env.API_URL);

    this.brand_logo = `${parsed.origin}/images/cvi/${this.$route.params.client}/logo.png`;
    this.$refs.brand.addEventListener('error', () => {
      this.brand_logo = '/img/sv-primary-logo-col.png';
    });
  },
  methods: {
    redirect() {
      const routeData = this.$router.resolve({path: `/${this.$route.query.conf}`});
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  header {
    background: white;
    min-height:51px;
  }

  @include media-breakpoint-down(xs) {
    header {
      position: relative;
    }
  }
</style>
