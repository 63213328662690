/* eslint-disable camelcase */
import Vue from 'vue';
import store from './index';
import axios from 'axios';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';
import {userStore} from './__STORE_user';
import {pexipStore} from './__STORE_pexip';
import Pusher from 'pusher-js';
/**
 *
 */
@Module
class CallStore extends VuexModule {
  // State
  camera = '';
  cameraMirrored = localStorage.getItem('cameraMirrored') == 'true' || false;
  isLocked = false;
  expandChat = false;
  vmr: {
    id?: number,
    deleted: boolean,
    meeting_id?: string,
    participant_limit: number,
    minutes_limit?: number,
    has_phone_license: boolean,
    has_video_device_license: boolean,
    has_xr_license: boolean,
    is_locked: boolean,
    webrtc: {
      alias: string,
      conferencing_node: string,
      pin: string,
      previous_pin: string,
      guest_pin: string
    },
    guest_pin_required: boolean,
    skype: {
      address: string,
    },
    alpha_numeric: {
      address: string,
    },
    numeric: {
      address: string,
      alt_address: string,
    },
    phone: {
      conference_id: string,
    },
    host: {
      name: string,
      last_name: string,
    },
    brand: {
      level: string,
      call_type: string,
      custom_call_type: string,
      guest_welcome_message: string,
      primary_color_hex: string,
      secondary_color_hex: string,
      logo: string,
      favicon: string,
      hero_image: string
    }
  } | null = null;
  callType = 'video';
  timeLimit = {} as any;
  callPhonebookData = null;
  callData = null;
  calledUser = null;
  attemptsCountsToAuth = 0;

  // Mutations

  /**
   *
   * @param {boolean} lockState
   */
  @Mutation
  private __setLockState(lockState: boolean) {
    this.isLocked = lockState;
  }

  /**
   *
   * @param {string} camera
   */
  @Mutation
  private __setCameraState(camera: string) {
    this.camera = camera;
  }

  /**
   *
   * @param {boolean} mirrorState
   */
  @Mutation
  private __setCameraMirrored(mirrorState: boolean) {
    this.cameraMirrored = mirrorState;
  }

  /**
   *
   * @param {Object} vmrData
   */
  @Mutation
  private __setVmr(vmrData: any) {
    this.vmr = vmrData;
  }

  /**
   *
   * @param {string} type
   */
  @Mutation
  private __setCallTypeState(type: string) {
    this.callType = type;
  }

  /**
   * Mutation to clear the vmr state
   */
  @Mutation
  private __clearVMR() {
    this.vmr = null;
  }

  /**
   *
   * @param {boolean} state
   */
  @Mutation
  private __setChatExpand(state: boolean) {
    this.expandChat = state;
  }

  /**
 * Mutation to set data from private channel
 * @param {any} payload - data from private channel
 */
  @Mutation
  private __setDataFormPhonebookCall(payload: any): void {
    this.callPhonebookData = payload;

    if (payload?.user?.id && document.hidden) {
      const notifyMe = () => {
        const notification = new Notification('Simply Video', {
          tag: 'ache-mail',
          body: `${payload.user.name} ${payload.user.last_name} is calling...`,
        });

        return notification;
      };

      if (Notification.permission === 'granted') notifyMe();
    }
  }

  /**
 * Mutation to set data from private channel
 * @param {any} payload - data from missed call
 */
  @Mutation
  private __setDataFormCall(payload: any): void {
    this.callData = payload;
  }

  /**
   *
   * @param {any} payload
   */
  @Mutation
  private __setCalledUser(payload: any) {
    this.calledUser = payload;
  }

  /**
   *
   * @param {any} payload
   */
  @Mutation
  private __setAttemptsCountsToAuth() {
    this.attemptsCountsToAuth += 1;
  }

  // Actions
  /**
   * Action to enable mirroring of camera image, sets value into localstorage
   */
  @Action
  async mirrorCamera() {
    const newValue = !this.cameraMirrored;
    this.__setCameraMirrored(newValue);
    localStorage.setItem('cameraMirrored', newValue ? 'true' : 'false');
  }

  /**
   * Action to expand or shrink the Chat
   * @param {boolean} state
   */
  @Action
  async setChatExpand(state) {
    this.__setChatExpand(state);
  }

  /**
   *
   * @param {boolean} value - lock status to set
   */
  @Action
  async setLock(value: boolean) {
    this.__setLockState(value);
  }

  /**
   * Get the room details of the conference being joined, is smart and will only fetch if the passed alias
   * is different to that of the VMR already in the store (cache)
   * @param {string} alias - alias of the VMR to be fetched
   * @return {Promise<any>} vmr - the data of the vmr requested
   */
  @Action
  async getVMR(alias: string): Promise<any> {
    if (!(this.vmr && this.vmr.webrtc.alias === alias)) {
      mainStore.setStatus('loading');
      const {data: vmr} = await axios({url: `/join-meeting-room/${alias}`, method: 'GET'})
          .catch((err) => {
            mainStore.setStatus('error');
          }) as any;
      this.__setVmr(vmr);
      mainStore.setStatus('success');
      return vmr;
    }
  }

  /**
   *
   * @param {Object} payload - email invites payload
   * @return {Promise} api call response
   */
  @Action
  async sendEmailInvites(payload: Object): Promise<any> {
    return new Promise((reslove, reject) => {
      axios({url: '/invite-participant', data: payload, method: 'POST'})
          .then((resp) => {
            reslove(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Action to call to remote exepert and answer call
   * @param {any} data - data answer
   * @return {Promise} response to call in phonebook
   */
  @Action
  async callToPhonebook(data: any): Promise<any> {
    let url = `/get-call?userId=${data.userId}`;
    if (data.status) url += `&status=${data.status}`;
    if (data.callHistoryId) url += `&callHistoryId=${data.callHistoryId}`;
    return new Promise((reslove, reject) => {
      axios({url, method: 'GET'})
          .then((resp) => {
            reslove(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Init to private channel using pusher
   * @param {any} payload - remote expert id
   * @return {any} channel from socket
  */
  @Action
  async getCallFromPhonebook(payload: any): Promise<any> {
    const token = localStorage.getItem('token');
    const API_URL = process.env.VUE_APP_API_URL ?
      process.env.VUE_APP_API_URL :
      'https://services.svcore.io/api/';
      // 'https://api.' + location.host + '/api/';

    if (!API_URL) return null;

    const key = await axios({url: '/device-setup', method: 'GET'}).then((resp) => resp.data['chat_key']);

    if (key) {
      const pusher = new Pusher(key, {
        cluster: 'eu',
        forceTLS: true,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        authorizer: ({name}) => ({
          authorize: async (socketId, callback) => {
            try {
              const {data} = await axios.post(API_URL + 'broadcasting/auth', {socket_id: socketId, channel_name: name});
              callback(null, {auth: data.auth});
            } catch (e) {
              if (this.__GETTERattemptsCountsToAuth < 5) {
                this.__setAttemptsCountsToAuth();
                this.getCallFromPhonebook(payload);
              } else {
                Vue.$toast.error('Something was wrong with phonebook system');
              }
            }
          },
        }),
      });

      const channel = pusher.subscribe(`private-user.${payload.id}`);

      channel.bind(`user.${payload.id}`, (data :any) => {
        if (payload.isCall) {
          this.__setDataFormPhonebookCall(data.data);
        } else {
          this.__setDataFormCall(data.data);
        }
      });

      return pusher;
    } else return null;
  }

  /**
   *
   * @param {Object} payload - hailMary payload
   * @return {Promise} api call response
   */
  @Action
  async hailMary(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({url: '/hail-mary', data: payload, method: 'POST'})
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @param {Object} payload - endpoint to dial payload
   * @return {Promise} api call response
   */
  @Action
  async dialInEndpoint(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({url: '/dial-in-endpoint', data: payload, method: 'POST'})
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @param {string} userId - user id
   * @return {Promise}
   * @memberof PexipStore
   */
  @Action
  async startRecording(userId: string): Promise<void> {
    if (userStore.__GETTERisRecordingAllowed) {
      return axios({url: '/recordings', data: {
        meeting_room_id: this.vmr.id,
        userId,
      }, method: 'POST'})
          .then(async ({data}) => {
            const rtmp = data.rtmp;
            const recordingParticipantName = data.recording_participant_name as string;
            await pexipStore.startRecording({
              rtmp,
              name: recordingParticipantName,
            });
          });
    } else throw new Error('Recording Is Not Allowed In This VMR');
  }

  /**
   *
   * @param {number} userId
   * @return {Promise}
   * @memberof PexipStore
   */
  @Action
  async expertRecording(userId: number): Promise<void> {
    if (userStore.__GETTERisRecordingAllowed) {
      axios({url: `/init-record?userId=${userId}&meetingRoomId=${this.vmr.id}`, method: 'GET'})
          .then(() => {
            console.log('then');
            pexipStore.setIsRecordingState(true);
          });
    } else throw new Error('Recording Is Not Allowed In This VMR');
  }

  /**
   *
   * @return {Promise}
   * @memberof PexipStore
   */
  @Action
  async stopRecording(): Promise<void> {
    return pexipStore.stopRecording();
  }

  /**
   *
   * @param {string} type - call type string to set
   */
  @Action
  async setCallType(type: string) {
    const value = (type === 'audioonly' ? 'audioonly' : 'video');
    this.__setCallTypeState(value);
  }

  /**
   *
   * @param {string} camera - user camera string to set
   */
  @Action
  async setCamera(camera: string) {
    this.__setCameraState(camera);
  }

  /**
   * Function to clear out the VMR state
   */
  @Action
  async clearVMR() {
    this.__clearVMR();
  }

  // Getters

  /**
   *
   */
  get __GETTERcameraMirrorState() {
    return this.cameraMirrored;
  }

  /**
   *
   */
  get __GETTERcallType() {
    return this.callType;
  }

  /**
   *
   */
  get __GETTERcamera() {
    return this.camera;
  }

  /**
   *
   */
  get __GETTERlockState() {
    return this.isLocked;
  }

  /**
   *
   */
  get __GETTERvmr() {
    return this.vmr;
  }

  /**
   *
   */
  get __GETTERhasVideoLicense() {
    return this.vmr.has_video_device_license;
  }

  /**
   *
   */
  get __GETTERhasXRLicense() {
    return this.vmr.has_xr_license;
  }

  /**
   *
   */
  get __GETTERhasPhoneLicense() {
    return this.vmr.has_phone_license;
  }

  /**
   *
   */
  get __GETTERcallAlias() {
    return this.vmr.webrtc.alias;
  }

  /**
   *
   */
  get __GETTERexpandChat() {
    return this.expandChat;
  }


  /**
   * Get call from phonebook
   */
  get __GETTERcallPhonebookData() {
    return this.callPhonebookData;
  }

  /**
   * Get call data from web sockets
   */
  get __GETTERcallData() {
    return this.callData;
  }

  /**
   * Get call status
   */
  get __GETTERcalledUser() {
    return this.calledUser;
  }

  /**
   * Get the attempts count to auth
   */
  get __GETTERattemptsCountsToAuth() {
    return this.attemptsCountsToAuth;
  }
}

export const callStore = new CallStore({store, name: 'callStore'});
