
import Component, {mixins} from 'vue-class-component';

import Colours from '@/mixins/Colours';

import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/SideBar.vue';
import MainNavBar from '@/components/MainNavBar.vue';
import CallNavBar from '@/components/CallNavBar.vue';
import CviNavBar from '@/components/StandaloneNavBar.vue';
import PremiumAccount from '@/components/PremiumAccount.vue';
import PhonebookModal from '@/components/PhonebookModal.vue';
import NotificationBox from '@/components/NotificationBox.vue';
import {Spinners} from '@/components';

import {authStore} from '@/store/__STORE_auth';
import {userStore} from '@/store/__STORE_user';
import {mainStore} from '@/store/__STORE_main';
import {brandingStore} from '@/store/__STORE_branding';
import {meetingStore} from './store/__STORE_meetings';
import {Watch} from 'vue-property-decorator';
import {initializeApp} from 'firebase/app';
import {getMessaging, onMessage, getToken} from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDN1ROKAO53P1Xzb6bTAg_f93NjMDqpIFs',
  authDomain: 'simply-video-vuzix.firebaseapp.com',
  projectId: 'simply-video-vuzix',
  storageBucket: 'simply-video-vuzix.appspot.com',
  messagingSenderId: '258109837905',
  appId: '1:258109837905:web:a7552e26779516356a9e70',
  measurementId: 'G-H0PTKW63VD',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging(app);
const storeDeviceToken = localStorage.getItem('storeDeviceToken');
const token = localStorage.getItem('token');
if ('Notification' in window) {
  if (Notification.permission === 'denied') {
    // If the permission is denied
    localStorage.removeItem('storeDeviceToken');
  } else if (Notification.permission === 'granted' || Notification.permission === 'default') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted' || permission === 'default') {
        getToken(messaging, {vapidKey: 'BGEGW-ddy9fsnbRsp3P0Ej4zGr6A-N8h--Dt03S7SFoqljUXU2AwKCa6OAzdUAmE0wWiSp7xKBrP-UcXgpigYTY'}).then((currentToken) => {
          if (currentToken) {
            authStore.setDeviceToken(currentToken);
            if ( token && !storeDeviceToken) {
              localStorage.setItem('storeDeviceToken', currentToken);
              const payload = {
                device_token: currentToken,
                device_type: 'web',
              };
              authStore.storeDeviceToken(payload);
            }
          }
        });
      }
    });
  }
}

@Component({
  components: {
    NavBar,
    SideBar,
    MainNavBar,
    CallNavBar,
    PremiumAccount,
    CviNavBar,
    PhonebookModal,
    NotificationBox,
    ...Spinners,
  },
})
/**
 * Main entry point of the application, holds all the top level components
 */
export default class App extends mixins(Colours) {
  name: 'App';

  isFullscreen = false;
  isShowPremium = false;
  isNotification = false;
  premiumPopupTexts = {} as any;
  defaultPrimaryColor = '';
  defaultSecondaryColor = '';
  roomBrand = null;
  status = mainStore.status;
  isLoading = false;
  pushNotificationData = null;

  /**
 *
 */
  created() {
    onMessage(messaging, (payload) => {
      localStorage.setItem('guest_pin', JSON.parse(payload.data.body).guest_pin);
      this.pushNotificationData = payload;
      this.isNotification = true;
    });
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'notificationData') {
        const notificationData = JSON.parse(event.data.data.data.body).guest_pin;
        localStorage.setItem('guest_pin', notificationData);
      }
    });
  }

  /**
   *
   */
  @Watch('status', {immediate: true})
  handleStatus() {
    if (this.status === 'loading') {
      this.isLoading = true;
    }
    this.isLoading = false;
  }

  /**
   * Second method to be called in the Vue stack
   * Initialises the session and the feedback tool in staging only
   */
  async mounted() {
    // await mainStore.requestNotificationPermissions(); // commented out for now as we are not using
    if (this.isLoggedIn) {
      await userStore.getUserProfile()
          .then(async () => {
            await userStore.getNotifications();
            await meetingStore.getStorage();
          })
          .catch(async (err) => {
            if (err.response.status === 403) {
              await authStore.logout();
              if (this.$route.meta.requiresAuth) {
                await this.$router.push({
                  name: 'login',
                });
              }
            }
          });
    }
    // this.initSession();

    const routes = this.$router.options.routes;
    mainStore.protectRoutes(routes);

    if (process.env.NODE_ENV === 'staging') {
      const win = window as any;
      win.Userback = win.Userback || {};
      win.Userback.access_token = '30569|45370|dAXj7L1TJsTPsbRDupQKJZCSY';
      (function(d) {
        const s = d.createElement('script'); s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
      })(document);
    }
  }

  /**
   * Open phonebook modal
   */
  openPhonebook() {
    window.$('#phonebookModal').modal('show');
    setTimeout(() => {
      meetingStore.setPhonebookModalState(true);
    }, 100);
  }

  /**
   * Close phonebook modal
   */
  closeRequest() {
    window.$('#phonebookModal').modal('hide');
    setTimeout(() => {
      meetingStore.setPhonebookModalState(false);
    }, 100);
  }

  /**
   * Sets the room brand for the given vmr
   * @param {any} roomBrand - the room branding to apply
   */
  setRoomBrand(roomBrand: any) {
    this.roomBrand = roomBrand;
  }

  /**
   * Shows the premium pop up modal
   * @param {any} premiumPopupTexts - object that holds the text information required for the popup
   */
  showPremiumFeaturePopup(premiumPopupTexts: any) {
    this.isShowPremium = true;
    this.premiumPopupTexts = premiumPopupTexts || {};
  }

  /**
   * Hides the premium feature pop up window
   */
  hidePremiumFeaturePopup() {
    this.isShowPremium = false;
  }

  /**
   * Enables fullscreen
   */
  enableFullscreen() {
    this.isFullscreen = true;
  }

  /**
   * Disables full screen
   */
  disableFullscreen() {
    this.isFullscreen = false;
    this.setRoomBrand(null);
  }

  /**
   * Initialises the session and checks the token of the logged in user
   */
  async initSession() {
    const token = localStorage.getItem('token');
    if (token) {
      await authStore.refreshToken()
          .catch(async () => {
            await authStore.logout();
            if (this.$route.meta.requiresAuth) {
              await this.$router.push({
                name: 'login',
              });
            }
          });
    }
  }

  // Computed

  /**
   * Gets whether the router is on a main page
   */
  get isMainPage() {
    return this.$route.name === 'home' ||
      this.$route.name === 'login' ||
      this.$route.name === 'register' ||
      this.$route.name === 'email-verify' ||
      this.$route.name === 'forgot-password' ||
      this.$route.name === 'reset-password' ||
      this.$route.name === 'register-via-invite' ||
      this.$route.name === 'join-meeting' ||
      this.$route.name === 'welcome-guest' ||
      this.$route.name === 'catch-all' ||
      this.$route.name === 'host' ||
      this.$route.name === 'host-one-time-meeting' ||
      this.$route.name === 'other-ways-to-join' ||
      this.$route.name === 'video-device-instructions' ||
      this.$route.name === 'alpha-numeric-instructions' ||
      this.$route.name === 'numeric-instructions' ||
      this.$route.name === 'phone-instructions' ||
      this.$route.name === 'microsoft-instructions' ||
      this.$route.name === 'skype-instructions' ||
      this.$route.name === 'teams-instructions' ||
      this.$route.name === 'after-call' ||
      this.$route.name === 'alternate';
  }

  /**
   * Gets whether the router is on the alternative dial in page
   */
  get isStandAlone() {
    return this.$route.name === 'alternate';
  }

  /**
   * Gets whether the router is on a call page
   */
  get isCallPage() {
    return this.$route.name === 'video-device-instructions' ||
      this.$route.name === 'other-ways-to-join' ||
      this.$route.name === 'host' ||
      this.$route.name === 'welcome-guest' ||
      this.$route.name === 'catch-all' ||
      this.$route.name === 'alpha-numeric-instructions' ||
      this.$route.name === 'numeric-instructions' ||
      this.$route.name === 'phone-instructions' ||
      this.$route.name === 'microsoft-instructions' ||
      this.$route.name === 'skype-instructions' ||
      this.$route.name === 'teams-instructions' ||
      this.$route.name === 'call';
  }

  /**
   * Gets the router style (the main component of the application)
   */
  get routerViewStyle() {
    const style = this.isFullscreen ? {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    } : {};

    return style;
  }

  /**
   * Returns whether the user is logged in or not
   */
  get isLoggedIn() {
    return authStore.__GETTERisUserLoggedIn;
  }

  /**
   * Returns the logged in user's profile
   */
  get authUser() {
    return userStore.__GETTERuserProfile;
  }

  /**
   * Gets the user's notifications
   */
  get notifications() {
    return userStore.__GETTERnotifications;
  }

  /**
   * Gets the phonebook modal state
   */
  get showPhonebookModal() {
    return meetingStore.showPhonebookModal;
  }

  /**
   * Gets the global style for the application
   */
  get globalStyle() {
    let primaryColor = brandingStore.__GETTERdefaultPrimary;
    let secondaryColor = brandingStore.__GETTERdefaultSecondary;

    if (this.isLoggedIn) {
      primaryColor = '#c8c9cc'; // While data is loading, everything is light gray
      const brand = (this.authUser && this.authUser.brand) ? this.authUser.brand : null;

      if (brand && brand.primary_color_hex) {
        primaryColor = this.authUser.brand.primary_color_hex;
        secondaryColor = this.authUser.brand.secondary_color_hex;
      }

      if (brand && !brand.primary_color_hex) {
        primaryColor = this.defaultPrimaryColor; // The default orange color for authorised users without custom branding
        secondaryColor = this.defaultSecondaryColor;
      }
    }

    if (this.roomBrand) {
      primaryColor = this.roomBrand.primary_color_hex || primaryColor;
      secondaryColor = this.roomBrand.secondary_color_hex || secondaryColor;
    }

    return {
      '--primary-color': primaryColor,
      '--primary-color-darker': this.__MIXINdarken(primaryColor),

      '--secondary-color': secondaryColor,
      '--secondary-color-darker': this.__MIXINdarken(secondaryColor),
    };
  }
}
