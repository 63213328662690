<template>
  <div
    id="phonebookModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      v-click-outside="{exclude: ['.modal-dialog', '.close-modal', '.phonebook-modal-header'], handler: 'closeModal'}"
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="phonebook-modal-header">
          <h2 class="phonebook-modal-title">
            Phonebook
          </h2>
          <button
            type="button"
            class="close close-modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="search-block">
          <div class="search-icon">
            <i class="fas fa-search" />
          </div>
          <input
            v-model="search"
            type="search"
            placeholder="Search"
            aria-label="Search"
          >
        </div>
        <div
          v-if="loading"
          class="d-flex justify-content-center"
        >
          <div
            class="spinner-border"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <ul
          v-else-if="contacts.length"
          class="phonebook-users"
        >
          <li
            v-for="contact in contacts"
            :key="contact.id"
            class="user"
          >
            <div class="name-and-status">
              <div class="short-name">
                {{ contact.name[0] }}{{ contact.last_name[0] }}
              </div>
              <div class="user-block">
                <p class="name">
                  {{ contact.name }} {{ contact.last_name }}
                </p>
                <p :class="['status', contact.is_available ? 'active' : 'passive']">
                  <span
                    v-if="contact.is_available"
                    class="active-circle"
                  />
                  {{ contact.is_available ? 'Available' : 'Not available' }}
                </p>
              </div>
            </div>
            <button
              :class="['btn', contact.is_available ? 'btn-primary' : 'btn-try']"
              @click="goCall(contact)"
            >
              {{ contact.is_available ? 'Call' : 'Try' }}
            </button>
          </li>
        </ul>
        <div v-else>
          Phonebook contacts are not found...
        </div>
      </div>
    </div>
    <notification-modal
      v-if="enableNotification"
      @close="closeNotificationModal"
    />
  </div>
</template>

<script>
import {meetingStore} from '@/store/__STORE_meetings';
import {callStore} from '@/store/__STORE_call';
import {userStore} from '@/store/__STORE_user';
import NotificationModal from '@/components/NotificationModal.vue';
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging';
import {authStore} from '@/store/__STORE_auth';

export default {
  components: {
    NotificationModal,
  },
  data() {
    return {
      timeout: null,
      search: '',
      loading: false,
      isNotificationSend: false,
      enableNotification: false,
      checkPermission: false,
    };
  },
  computed: {
    contacts() {
      return meetingStore.__GETTERphonebookContacts;
    },
    authUser() {
      return userStore.__GETTERuserProfile;
    },
    webrtcAlias() {
      return this.authUser?.meeting_room ? this.authUser.meeting_room.webrtc.alias : '';
    },
    deviceToken() {
      return authStore.__GETTERdeviceToken;
    },
  },
  watch: {
    search: {
      handler: function(val) {
        this.loading = true;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          meetingStore.getPhonebookContacts(val).catch(() => {
            this.$toast.error('Failed To Request Phonebook Contact');
          }).finally(() => {
            this.loading = false;
          });
        }, 1000);
      },
      immediate: true,
    },
  },
  created() {
    this.showModal();
  },
  methods: {
    showModal() {
      setTimeout(() => {
        window.$('#phonebookModal').modal('show');
      }, 100);
    },
    closeModal() {
      if (event.target.closest('.modal-dialog') ||
        event.target.closest('.close-modal') ||
        event.target.closest('.phonebook-modal-header') || event.target.closest('.btn-try') ||
        event.target.closest('.btn-primary')) {
      // Close the modal only if the close button is clicked
        this.$emit('close-request');
      }
    },
    closeNotificationModal() {
      this.enableNotification= false;
      this.isNotificationSend= false;
      this.checkPermission= true;
    },
    goCall(contact) {
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries
      if (this.checkPermission===false) {
        const firebaseConfig = {
          apiKey: 'AIzaSyDN1ROKAO53P1Xzb6bTAg_f93NjMDqpIFs',
          authDomain: 'simply-video-vuzix.firebaseapp.com',
          projectId: 'simply-video-vuzix',
          storageBucket: 'simply-video-vuzix.appspot.com',
          messagingSenderId: '258109837905',
          appId: '1:258109837905:web:a7552e26779516356a9e70',
          measurementId: 'G-H0PTKW63VD',
        };
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        // // Get registration token. Initially this makes a network call, once retrieved
        // // subsequent calls to getToken will return from cache.
        const messaging = getMessaging(app);
        if ('Notification' in window) {
          if (Notification.permission === 'denied') {
            this.isNotificationSend = true;
            this.enableNotification = true;
          } else if (Notification.permission === 'granted' || Notification.permission === 'default') {
            this.enableNotification = false;
            this.isNotificationSend = false;
            this.checkPermission = true;
            // If the user hasn't made a choice yet, you can prompt the permission dialog
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted' || permission === 'default') {
                getToken(messaging, {vapidKey: 'BGEGW-ddy9fsnbRsp3P0Ej4zGr6A-N8h--Dt03S7SFoqljUXU2AwKCa6OAzdUAmE0wWiSp7xKBrP-UcXgpigYTY'});
              }
            }).catch((err) => {
              console.log('Unable to request permission.', err);
            });
          }
        }
      }
      if (this.isNotificationSend === false && this.checkPermission === true) {
        const payload = {
          emails: [contact.invite_emailed_to],
          alias: this.webrtcAlias,
          phonebook_invite: 1,
        };
        callStore.sendEmailInvites(payload)
            .then(() => {
              this.$emit('close-request');
              if (contact.is_available) {
                const payload = {
                  userId: contact.id,
                };
                callStore.callToPhonebook(payload);
              }
              if (this.$route.name !== 'ongoing-meeting') {
                this.$router.push({name: 'host', params: {webrtcAlias: this.webrtcAlias}});
                callStore.__setCalledUser(contact);
              } else this.$toast.success('Invitation to user has been sent');
            })
            .catch(() => {
              this.$toast.error('Unable to send invite. Something went wrong');
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";
  .modal-dialog,
  .modal-content{
    width: 374px;
  }
  .modal-content {
    position: relative;
    margin-top: 11vh;
    min-height: 200px;
    max-height: 490px;
    overflow: auto;
    padding: 24px 32px 0 32px;

    .phonebook-modal-header {
      .phonebook-modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: $color-body;
      }
      .close-modal {
        font-size: 24px;
        position: absolute;
        top: 12px;
        right: 20px;
      }
    }

    .search-block {
      position: relative;
      margin: 12px 0 20px 0;

      .search-icon {
        position: absolute;
        top: 8px;
        left: 14px;
      }

      input[type=search] {
        width: 100%;
        height: 40px;
        border: 1px solid #D4D4D4;
        border-radius: 4px;
        padding-left: 40px;
      }
    }

    .phonebook-users {
      padding: 0;

      .user {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .name-and-status {
          display: flex;

          .short-name {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: #3E3E40;
            background: linear-gradient(180deg, #ACDBDB 54.01%, #83C0C0 100%);
          }

          .user-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 12px;

            .name {
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              color: #3E3E40;
            }

            .status {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;

              .active-circle {
                width: 8px;
                height: 8px;
                display: block;
                border-radius: 50%;
                margin-right: 4px;
                background-color: rgba(75, 179, 75, 0.8);
              }
            }

            .active {
              color: rgba(75, 179, 75, 0.8);
            }

            .passive {
              color: rgba(62, 62, 64, 0.5);
            }
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }
</style>
