import store from './index';
import axios from 'axios';
axios.defaults.headers = {
  ...axios.defaults.headers,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';

import Message from '@/types/Message';
import ChatFile from '@/types/ChatFile';
import Reaction from '@/types/Reaction';
// import MessageReply from '@/types/MessageReply';
import ChatNotification from '@/types/ChatNotification';
import ChatParticipant from '@/types/ChatParticipant';

import {pexipStore} from './__STORE_pexip';

import Echo from 'laravel-echo';
declare const window: any;
window.Pusher = require('pusher-js');

/**
 * A store module to hold all the information required for Chat 2.0 feature
 */
@Module
class MessageStore extends VuexModule {
  // State
  apiURL = '';
  pusherKey = '';
  token = '';
  counter = 0;
  connected = false;
  connecting = false;
  sendingMessage = false;
  fileUpload = {
    uploading: false,
    uploaded: false,
    uuid: '',
    thumbnail: '',
    showPreview: false,
  };
  messages = <Message[]>[];
  notifications = <ChatNotification[]>[];
  participants = <ChatParticipant[]>[];
  reactions = <Reaction[]>[];
  mentionList = [];
  mentionedUuids = <string[]>[];
  // replies = <MessageReply[]>[];
  imageMarkup = {
    selectedFile: <ChatFile>null,
    showImageModal: false,
    showImageCanvas: false,
    markupActive: false,
  };
  showNotification = {
    state: false,
  }
  selectedMessageUuid = '';
  reactionEmojis = {
    checkmark: {
      unicode: 'U+2714',
      html: '&#10004;',
    },
    cross: {
      unicode: 'U+2717',
      html: '&#10007;',
    },
    bangbang: {
      unicode: 'U+203C',
      html: '&#8252;',
    },
    question: {
      unicode: 'U+003F',
      html: '&#63;',
    },
    raisedHands: {
      unicode: 'U+1F64C',
      html: '&#128588;',
    },
  }

  // Mutators

  /**
   * Mutation to clear out the state everytime a new call is joined
   */
  @Mutation
  private async __clearState(): Promise<void> {
    this.sendingMessage = false;
    this.fileUpload = {
      uploading: false,
      uploaded: false,
      uuid: '',
      thumbnail: '',
      showPreview: false,
    };
    this.selectedMessageUuid = '';
    this.mentionList = [];
    this.messages = <Message[]>[];
    this.notifications = <ChatNotification[]>[];
    this.participants = <ChatParticipant[]>[];
    this.reactions = <Reaction[]>[];
    this.mentionedUuids = <string[]>[];
    this.counter = 0;
    this.connected = false;
  }

  /**
   * Mutator to set the state of the sendingMessage value
   * @param {boolean} bool - the boolean value to set the state to
   */
  @Mutation
  private async __setSendingMessageState(bool: boolean): Promise<void> {
    this.sendingMessage = bool;
  }

  /**
   * Pushes the passed message into the messages array when a new message is sent or recieved
   * @param {Message} message - the new message to push into the array
   */
  @Mutation
  private async __pushMessage(message: Message): Promise<void> {
    this.messages.push(message);
  }

  /**
   * Mutator to add the reaction to a message
   * @param {Reaction} reactionObject - the reaction object from the API to use to add the reaction to the message
   */
  @Mutation
  private __addMessageReaction(reactionObject: Reaction): void {
    this.reactions.push(reactionObject);
  }

  /**
   * Remove a reaction from a message given its uuid
   * @param {String} uuid - the uuid of the reaction to remove
   */
  @Mutation
  private __removeMessageReaction(uuid: String): void {
    const found = this.reactions.find((reaction) => {
      return reaction.uuid === uuid;
    });
    const index = this.reactions.indexOf(found);
    if (index !== -1) {
      this.reactions.splice(index, 1);
    }
  }

  /**
   * Mutator to add the reaction to a message
   * @param {string} uuid - the reaction object from the API to use to add the reaction to the message
   */
  @Mutation
  private __addMessageMention(uuid: string): void {
    this.mentionedUuids.push(uuid);
  }

  /**
   * Remove a reaction from a message given its uuid
   * @param {String} uuid - the uuid of the reaction to remove
   */
  @Mutation
  private __removeMessageMention(uuid: String): void {
    const found = this.mentionedUuids.find((mention) => {
      return mention === uuid;
    });
    const index = this.mentionedUuids.indexOf(found);
    if (index !== -1) {
      this.mentionedUuids.splice(index, 1);
    }
  }

  /**
   * Remove a reaction from a message given its uuid
   * @param {string} uuid - the uuid of the reaction to remove
   */
  @Mutation
  private __setSelectedMessageUuid(uuid: string): void {
    this.selectedMessageUuid = uuid;
  }

  /**
   * Push a message reply to the replies array
   * @param {MessageReply} reply - the reply message
   */
  // @Mutation
  // private __addMessageReply(reply: MessageReply): void {
  //   this.replies.push(reply);
  // }

  /**
   * When a new participant joins the basic data for chat is pushed here
   * @param {ChatParticipant} participant - the new participant that has joined
   */
  @Mutation
  private __pushParticipant(participant: ChatParticipant): void {
    this.participants.push(participant);
  }

  /**
   * When a new participant joins the basic data for mentions is pushed here
   * @param {ChatParticipant} participant - the new participant that has joined
   */
  @Mutation
  private __pushMentionableParticipant(participant: ChatParticipant): void {
    const mentionPartipant = {
      'participant_uuid': participant.participant_uuid,
      'value': participant.display_name,
      'label': participant.display_name,
    };
    const found = this.mentionList.find((part) => {
      return part.participant_uuid === participant.participant_uuid;
    });
    const index = this.mentionList.indexOf(found);
    index === -1 ? this.mentionList.push(mentionPartipant) : null;
  }

  /**
   * Removes the participant from mentionables list
   * @param {ChatParticipant} participant - the participant to remove
   */
  @Mutation
  private __deleteMentionableParticipant(participant: ChatParticipant): void {
    const found = this.mentionList.find((part) => {
      return part.participant_uuid === participant.participant_uuid;
    });
    const index = this.mentionList.indexOf(found);
    if (index !== -1) {
      this.mentionList.splice(index, 1);
    }
  }

  /**
   * Mutation to push a new mention notification to the array
   */
  @Mutation
  private __pushNotification(): void {
    this.notifications.push({
      type: 'mention',
    });
  }

  /**
   * Mutation to pop a mention notification from the array
   */
  @Mutation
  private __popNotification(): void {
    this.notifications.pop();
  }

  /**
  * Mutation to set the state of the show canvas variable
  * @param {boolean} state - boolean value to set the state to
  */
  @Mutation
  private __setShowImageModal(state: boolean): void {
    this.imageMarkup.showImageModal = state;
  }

  /**
  * Mutation to set the state of the show canvas variable
  * @param {boolean} state - boolean value to set the state to
  */
  @Mutation
  private __setShowImageCanvas(state: boolean): void {
    this.imageMarkup.showImageCanvas = state;
  }

  /**
  * Mutation to set the state of the show canvas variable
  * @param {boolean} state - boolean value to set the state to
  */
  @Mutation
  private __setShowNotificationState(state: boolean): void {
    this.showNotification.state = state;
  }

  /**
  * Mutation to set the state of the show canvas variable
  * @param {boolean} state - boolean value to set the state to
  */
  @Mutation
  private __setMarkupActive(state: boolean): void {
    this.imageMarkup.markupActive = state;
  }

  /**
   * Sets whether a file is being uploaded or not
   * @param {boolean} value - the boolean value to set the uploading state to
   */
  @Mutation
  private __setUploadingState(value: boolean): void {
    this.fileUpload.uploading = value;
  }

  /**
   * Mutator to set the new uploaded file data to the state ready for message
   * @param {{
   *  uuid: string,
   *  thumbnail: string,
   * }} fileData - The new uploaded faile data to set into state
   */
  @Mutation
  private __setUploadedFile(fileData: {
    uuid: string,
    thumbnail: string
  }): void {
    this.fileUpload.uuid = fileData.uuid;
    this.fileUpload.thumbnail = fileData.thumbnail;
    this.fileUpload.uploading = false;
    this.fileUpload.uploaded = true;
    this.fileUpload.showPreview = true;
  }

  /**
   * Mutation to remove the uploaded file
   */
  @Mutation
  private __deleteUploadedFile(): void {
    this.fileUpload.uuid = '';
    this.fileUpload.thumbnail = '';
    this.fileUpload.uploading = false;
    this.fileUpload.uploaded = false;
    this.fileUpload.showPreview = false;
  }

  /**
   * Mutation to remove the uploaded file
   */
  @Mutation
  private __deleteOldMentions(): void {
    this.mentionedUuids = [];
  }

  /**
   * Sets the token state
   * @param {string} token - the auth token from the chat api
   */
  @Mutation
  private async __setToken(token: string): Promise<void> {
    this.token = token;
  }

  /**
   *
   * @param {{
   *  url: string,
   *  key: string,
   * }} config - pusher and api config
   */
  @Mutation
  private async __setConfig(config: {
    url: string,
    key: string,
  }): Promise<void> {
    this.pusherKey = config.key;
    this.apiURL = config.url;
  }

  /**
   *
   * @param {ChatFile} chatFile - chatFile coming from the message
   */
  @Mutation
  private async __setSelectedFile(chatFile: ChatFile): Promise<void> {
    this.imageMarkup.selectedFile = chatFile;
  }

  /**
   * Mutation to set the counter state
   * @param {number} num - the number to set the counter to
   */
  @Mutation
  private __setCounter(num: number): void {
    this.counter = num;
  }

  /**
   * Mutation to set the connected state boolean
   * @param {boolean} state - the boolean state to set the connected value to
   */
  @Mutation
  private __setConnectedState(state: boolean): void {
    this.connected = state;
  }

  /**
   * Mutation to set the connected state boolean
   * @param {boolean} state - the boolean state to set the connected value to
   */
  @Mutation
  private __setConnectingState(state: boolean): void {
    this.connecting = state;
  }


  // Actions
  /**
   * Action to clean up state after sending message
   */
  @Action
  async cleanupAfterMessageSend(): Promise<void> {
    this.__deleteUploadedFile();
    this.__deleteOldMentions();
  }
  /**
   * Get the state
   * @param {boolean} state
   */
  @Action
  async setShowImageModal(state: boolean): Promise<void> {
    this.__setShowImageModal(state);
  }

  /**
   * Get the state
   * @param {boolean} state
   */
  @Action
  async setShowNotificationState(state: boolean): Promise<void> {
    this.__setShowNotificationState(state);
  }

  /**
   * Action to add a mention
   * @param {string} uuid
   */
  @Action
  async addMentionUuid(uuid: string): Promise<void> {
    this.__addMessageMention(uuid);
  }

  /**
   * Action to remove a mention
   * @param {string} uuid
   */
  @Action
  async removeMentionUuid(uuid: string): Promise<void> {
    this.__removeMessageMention(uuid);
  }

  /**
   * Mutation to remove the uploaded file
   */
  @Action
  async deleteOldMentions(): Promise<void> {
    this.__deleteOldMentions();
  }

  /**
   * Get the state
   * @param {string} uuid
   */
  @Action
  async setSelectedMessageUuid(uuid: string): Promise<void> {
    this.__setSelectedMessageUuid(uuid);
  }

  /**
   * Gets the token for the user from the API so that they can connect to the chat
   * @param {ChatFile} chatFile
   */
  @Action
  async setSelectedFile(chatFile: ChatFile): Promise<void> {
    this.__setSelectedFile(chatFile);
  }

  /**
   * Gets the token for the user from the API so that they can connect to the chat
   * @param {{
   * participantUuid: string,
   * roomAlias: string,
   * registeredName: string,
   * displayName: string,
   * }} params
   */
  @Action
  async getToken(params: {
    participantUuid: string,
    roomAlias: string,
    registeredName: string,
    displayName: string,
}): Promise<void> {
    this.__setConnectingState(true);
    const convertedParams = {
      participant_uuid: params.participantUuid,
      room_alias: params.roomAlias,
      registered_name: params.registeredName,
      display_name: params.displayName,
    };
    axios({url: '/token', baseURL: this.apiURL, params: convertedParams, method: 'GET'})
        .then(async (resp) => {
          const token = resp.data.token;
          await this.__setToken(token);

          const echoConfig = {
            broadcaster: 'pusher',
            key: this.pusherKey,
            forceTLS: true,
            cluster: 'eu',
            authorizer: (channel: any, options?: any) => {
              return {
                authorize: (socketId: any, callback: any) => {
                  const data = {
                    socket_id: socketId,
                    channel_name: channel.name,
                    token: token,
                  };
                  axios({url: '/broadcasting/auth', baseURL: this.apiURL, data: data, method: 'POST'})
                      .then((response) => {
                        callback(false, response.data);
                      })
                      .catch((error) => {
                        callback(true, error);
                      });
                },
              };
            },
          };

          window.Echo = (new Echo(echoConfig));

          const channel = 'room-' + params.roomAlias;
          window.Echo.join(channel)
              .here((users: any) => {
                this.__setConnectingState(false);
                this.__setConnectedState(true);
                users.forEach((element: any) => {
                  this.__pushParticipant(element);
                  this.__pushMentionableParticipant(element);
                });
              })
              .listen('NewMessage', (e: any) => {
                if (e.participant_uuid !== pexipStore.__GETTERmyUuid) {
                  this.__pushMessage(e);
                  if (e.mention_uuids.length && e.mention_uuids.indexOf(pexipStore.__GETTERmyUuid) > -1) {
                    this.addMentionNotification();
                  }
                }
              })
              .listen('NewReaction', (e: any) => {
                if (e.participant_uuid !== pexipStore.__GETTERmyUuid) {
                  this.__addMessageReaction(e);
                }
              })
              .listen('CancelledReaction', (e: any) => {
                this.__removeMessageReaction(e.reaction_uuid);
              })
              .joining((user: any) => {
                this.__pushParticipant(user);
                this.__pushMentionableParticipant(user);
              })
              .leaving((user: any) => {
                this.__deleteMentionableParticipant(user);
              })
              .error((error: any) => {
                console.error('error', error);
              });
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            this.__setCounter(this.counter + 1);
            if (this.counter < 20) {
              setTimeout(() => {
                this.getToken({
                  participantUuid: params.participantUuid,
                  roomAlias: params.roomAlias,
                  registeredName: params.registeredName,
                  displayName: params.displayName,
                });
              }, 500);
            } else {
              this.__setConnectingState(false);
            }
          }
        });
  }

  /**
   * Action to initialise chat requirements
   * Api URL
   * Get the broadcast channel
   * Set watchers
   * @param {{
   *  conferenceUuid: string,
   *  participantUuid: string,
   *  roomAlias: string,
   *  registeredName: string,
   *  displayName: string,
   * }} conferenceParams - the required parameters to setup a new chat instance
   * conference_uuid: conferenceUuid,
        participant_uuid: participantUuid,
        room_alias: roomAlias,
        registered_name: registeredName,
        display_name: displayName,
   */
  @Action
  async initialiseChat(conferenceParams: {
    participantUuid: string,
    roomAlias: string,
    registeredName: string,
    displayName: string,
  }): Promise<void> {
    await this.__clearState();
    // let CHAT_URL = process.env.VUE_APP_CHAT_URL;
    // if(!CHAT_URL){
    //   CHAT_URL = 'https://chatapiprod.simplyvideo.net/';
    //   // CHAT_URL = 'https://chat.' + window.location.host;
    // }

    // Runtime env extraction
    let CHAT_URL = (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL;
    if (!CHAT_URL) {
      CHAT_URL = 'https://chat.' + window.location.host;
    }

    return new Promise((resolve, reject) => {
      axios({url: '/device-setup', method: 'GET'})
          .then((resp) => {
            this.__setConfig({
              url: CHAT_URL,
              key: resp.data['chat_key'],
            });
            this.getToken({
              participantUuid: conferenceParams.participantUuid,
              roomAlias: conferenceParams.roomAlias,
              registeredName: conferenceParams.registeredName,
              displayName: conferenceParams.displayName,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   *  open the modal
   */
  @Action
  async openImageModal(): Promise<void> {
    if (!this.imageMarkup.showImageCanvas) {
      this.__setShowImageModal(true);
    }
  }

  /**
   *  close the modal
   */
  @Action
  async closeImageModal(): Promise<void> {
    this.__setShowImageModal(false);
    this.__setSelectedFile(null);
  }

  /**
   *  the image being marked up
   */
  @Action
  async startImageMarkup(): Promise<void> {
    this.__setMarkupActive(true);
    this.__setShowImageCanvas(true);
  }

  /**
   * Action to cancel the annotation flow part way through before it reaches screenshare
   */
  @Action
  async cancelImageMarkup(): Promise<void> {
    this.__setMarkupActive(false);
    this.__setShowImageCanvas(false);
    this.__setShowImageModal(false);
  }

  /**
   * Action to push a new participant to the array
   * @param {ChatParticipant} participant - the new ChatParticipant object to push to the array
   */
  @Action
  pushParticipant(participant: ChatParticipant): void {
    this.__pushParticipant(participant);
  }

  /**
   * Action is called to send a new message to the chat
   * @param {string} text - the text message to send
   */
  @Action
  async sendMessage(text: string): Promise<any> {
    if (!this.sendingMessage) {
      await this.__setSendingMessageState(true);
      const message = {
        body: text,
        token: this.token,
        file_uuid: this.fileUpload.uploaded ? this.fileUpload.uuid : null,
      };
      if (this.mentionedUuids.length) {
        message['mention_uuids'] = this.mentionedUuids;
      }
      return new Promise((resolve, reject) => {
        axios({url: '/messages', baseURL: this.apiURL, data: message, method: 'POST'})
            .then((resp) => {
              this.__pushMessage(resp.data);
              this.cleanupAfterMessageSend();
              resolve(resp);
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        this.__setSendingMessageState(false);
      });
    }
  }

  /**
   * Action to add and then set a timeout to remove a mention notification
   */
  @Action
  addMentionNotification(): void {
    this.__pushNotification();
    this.showNotificationToParticipant();
    setTimeout(() => this.__popNotification(), 1000);
  }

  /**
   * Action to add and then set a timeout to remove a mention notification
   */
  @Action
  showNotificationToParticipant(): void {
    this.setShowNotificationState(true);
    setTimeout(() => this.setShowNotificationState(false), 3000);
  }

  /**
   * Action uploads the file to the server and sets the file state ready for message sending
   * @param {File | Blob} file - File object to be uploaded
   * @return {Promise} - api response
   */
  @Action
  uploadFile(file: File | Blob): Promise<any> {
    this.__setUploadingState(true);
    const data = new FormData();
    data.append('file', file);
    data.append('token', this.token);
    return new Promise((resolve, reject) => {
      axios({url: '/files', baseURL: this.apiURL, data: data, method: 'POST'})
          .then((resp) => {
            const uploadedFile = resp.data;
            this.__setUploadedFile({
              uuid: uploadedFile.uuid,
              thumbnail: uploadedFile.thumbnail_url,
            });
            resolve(resp);
          })
          .catch((err) => {
            this.__setUploadingState(false);
            reject(err);
          });
    });
  }

  /**
   * Action uploads the file to the server and sets the file state ready for message sending
   * @param {{
   *  emoji: string,
   *  messageUuid: string,
   * }} outboundReaction - The outbound Reaction Object with unicode emoji and message uuid
   * @return {Promise}
   */
  @Action
  handleReaction(outboundReaction: {
    emoji: string,
    messageUuid: string,
  }): Promise<any> {
    const messageUuid = outboundReaction.messageUuid;
    const emoji = outboundReaction.emoji;

    const reaction = this.__checkForReaction(outboundReaction.messageUuid);
    if (!reaction.exists) {
      // checking if already existing reactions
      return this.__sendReaction(messageUuid, emoji);
    } else {
      return new Promise((resolve, reject) => {
        this.__deleteReaction(reaction.reaction.uuid)
            .then((resp) => {
              if (emoji !== reaction.reaction.emoji) {
                this.__sendReaction(messageUuid, emoji)
                    .then((resp) => {
                      resolve(resp);
                    })
                    .catch((err) => {
                      reject(err);
                    });
              }
              resolve(resp);
            });
      });
    }
  }

  /**
   * Private helper to send the reaction if new, or update reaction
   * @param {string} messageUuid
   * @param {string} emoji - unicode string for emoji
   * @return {Promise}
   */
  private __sendReaction(messageUuid: string, emoji: string): Promise<any> {
    // checking if already existing reactions
    const data = new FormData();
    data.append('message_uuid', messageUuid);
    data.append('emoji', emoji);
    data.append('token', this.token);

    return new Promise((resolve, reject) => {
      axios({url: '/reactions', baseURL: this.apiURL, data: data, method: 'POST'})
          .then((resp) => {
            this.__addMessageReaction(resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * Handle the deletion of the emojis, or replacing an emoji
   * @param {string} uuid
   * @return {Promise}
   */
  private __deleteReaction(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {
        token: this.token,
      };
      axios({url: `/reactions/${uuid}`, baseURL: this.apiURL, data: data, method: 'DELETE'})
          .then((resp) => {
            this.__removeMessageReaction(uuid);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * Helper method that checks if a participant has previously sent a reaction
   * @param {string} messageUuid
   * @return {{
   *  exists: boolean,
   *  reaction: (Reaction|null),
   * }} - reaction found object
   */
  private __checkForReaction(messageUuid: string): {
    exists: boolean,
    reaction: (Reaction|null),
  } {
    const filtered = this.reactions.filter((reaction) => {
      return reaction.message_uuid === messageUuid && reaction.participant_uuid === pexipStore.__GETTERmyUuid;
    });
    if (filtered.length) {
      return {
        exists: true,
        reaction: filtered[0],
      };
    } else {
      return {
        exists: false,
        reaction: null,
      };
    }
  }

  /**
   * Remove the currently uploaded file
   */
  @Action
  deleteUploadedFile(): void {
    this.__deleteUploadedFile();
  }

  /**
   * @return {boolean}
   */
  get __GETTERshowFileUploaded(): boolean {
    return (this.fileUpload && this.fileUpload.showPreview);
  }

  /**
   * Get the thumbnail.
   * @return {string}
   */
  get __GETTERthumbnail(): string {
    return this.fileUpload.thumbnail;
  }

  /**
   * Get the file uuid
   * @return {string}
   */
  get __GETTERfileUUID(): string {
    return this.fileUpload.uuid;
  }

  /**
   * Get the UUID for the selected message
   * @return {string}
   */
  get __GETTERselectedMessageUuid(): string {
    return this.selectedMessageUuid;
  }

  /**
   * Check state for if image modal is shown
   * @return {boolean}
   */
  get __GETTERshowImageModal(): boolean {
    return this.imageMarkup.showImageModal;
  }

  /**
   * Check state for if showing Markup Canvas
   * @return {boolean}
   */
  get __GETTERshowMarkupCanvas(): boolean {
    return this.imageMarkup.showImageCanvas;
  }

  /**
   *  Get the selected File
   * @return {ChatFile}
   */
  get __GETTERselectedFile(): ChatFile {
    return this.imageMarkup.selectedFile;
  }

  /**
   * Get the file width for the selected file
   * @return {number}
   */
  get __GETTERselectedFileWidth(): number {
    return this.imageMarkup.selectedFile.width;
  }

  /**
   * Get the file height for the selected file
   * @return {number}
   */
  get __GETTERselectedFileHeight(): number {
    return this.imageMarkup.selectedFile.height;
  }

  /**
   * Check if markup is active for user
   * @return {boolean}
   */
  get __GETTERmarkupActive(): boolean {
    return this.imageMarkup.markupActive;
  }

  /**
   * Check state of file uploading
   * @return {boolean}
   */
  get __GETTERfileUploading(): boolean {
    return this.fileUpload.uploading;
  }

  /**
   *  Get all reaction emojis for use
   * @return {any}
   */
  get __GETTERreactionEmojis(): any {
    return this.reactionEmojis;
  }

  /**
   * Check state of Chat connected
   * @return {boolean}
   */
  get __GETTERisChatConnected(): boolean {
    return this.connected;
  }

  /**
   * Check for chat connection error
   * @return {boolean}
   */
  get __GETTERisChatConnectionError(): boolean {
    return this.counter >= 9 && !this.connected && !this.connecting && !this.token;
  }

  /**
   *  Check state of Chat Connecting
   * @return {boolean}
   */
  get __GETTERisChatConnecting(): boolean {
    return this.connecting;
  }

  /**
   * Get state of the notification for a user
   * @return {boolean}
   */
  get __GETTERshowNotificationState(): boolean {
    return this.showNotification.state;
  }

  /**
   * Get chatlist of historic participants
   * @return {ChatParticipant[]}
   */
  get __GETTERchatHistoricParticipants(): ChatParticipant[] {
    return this.participants;
  }

  /**
   * Get chatlist of mentionable participants
   * @return {any[]}
   */
  get __GETTERmentionListRoster(): any[] {
    return this.mentionList;
  }

  /**
   * Get list of mentionedUuids for this message
   * @return {string[]}
   */
  get __GETTERmentionedUuids(): string[] {
    return this.mentionedUuids;
  }
}

export const messageStore = new MessageStore({store, name: 'messageStore'});
