import store from './index';
import axios from 'axios';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';
import {userStore} from './__STORE_user';

/**
 *
 */
@Module
class AuthStore extends VuexModule {
    // State
    token = localStorage.getItem('token') || '';
    expiredIn = localStorage.getItem('expired_in') || '';
    deviceToken = '';

    // Mutators
    /**
     *
     * @param {string} token
     */
    @Mutation
    private async __setTokenState(token: string) {
      this.token = token;
    }

    /**
     *
     * @param {string} expiredIn
     */
    @Mutation
    private async __setExpiredInState(expiredIn: string) {
      this.expiredIn = expiredIn;
    }

    /**
     *
     */
    @Mutation
    private __logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('expired_in');
      localStorage.removeItem('storeDeviceToken');
      delete axios.defaults.headers.common['Authorization'];
      this.token = '';
      this.expiredIn = '';
    }

    /**
   *
   * @param {string} deviceToken
   */
   @Mutation
    private __setDeviceToken(deviceToken: string) {
      this.deviceToken = deviceToken;
    }

   // Actions
    /**
     * Refresh the user auth token used with the SV API
     * Allows for token to be refreshed up to 7 days old
     * @return {Promise} - api response
     */
    @Action
   async refreshToken(): Promise<any> {
     return new Promise((resolve, reject) => {
       axios({url: '/refresh-token', method: 'POST'})
           .then(async (resp) => {
             const token = resp.data.token;
             const expires = resp.data.expires;
             const curDate = new Date();
             delete axios.defaults.headers.common['Authorization'];
             curDate.setMinutes(curDate.getMinutes() + parseInt(expires));
             const expiredIn = curDate.getTime();
             localStorage.setItem('token', token);
             localStorage.setItem('expired_in', String(expiredIn));
             axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
             await this.__setTokenState(token);
             await this.__setExpiredInState(String(expiredIn));
             const timeout = (expires - 5) * 60 * 1000;
             setTimeout(async () => {
               await this.refreshToken();
             }, timeout);
             resolve(resp);
           })
           .catch((err) => {
             this.__logout();
             mainStore.setStatus('error');
             reject(err);
           });
     });
   }

    /**
     * API call to request XR token for generating QR code
     * @param {{
     *  email: string,
     *  password: string,
     * }} params
     * @return {Promise}
     */
    @Action
    async xrRequestToken(params: {
      email: string,
      password: string,
    }): Promise<any> {
      return new Promise((resolve, reject) => {
        axios({url: '/xr-request-token', data: params, method: 'POST'})
            .then((resp) => {
              resolve(resp);
            })
            .catch((err)=> {
              reject(err);
            });
      });
    }

    /**
     * Login a user
     * @param {Object} user - user login credentials
     * @return {Promise} - api response
     */
    @Action
    async ssoLogin(user: Object): Promise<any> {
      this.__logout();
      mainStore.setStatus('loading');
      return new Promise((resolve, reject) => {
        axios({url: '/sso-login', data: user, method: 'POST'})
            .then((resp) => {
              const token = resp.data.token;
              const expires = resp.data.expires;
              const curDate = new Date();
              curDate.setMinutes(curDate.getMinutes() + parseInt(expires));
              const expiredIn = curDate.getTime();

              localStorage.setItem('token', token);
              localStorage.setItem('expired_in', String(expiredIn));

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              const timeout = (parseInt(expires) - 5) * 60 * 1000;
              setTimeout(async () => {
                await this.refreshToken();
              }, timeout);
              this.__setTokenState(token);
              this.__setExpiredInState(String(expiredIn));
              userStore.getUserProfile();
              resolve(resp);
            })
            .catch((err) => {
              this.__logout();
              mainStore.setError();
              reject(err);
            });
      });
    }

    /**
     * Login a user
     * @param {Object} user - user login credentials
     * @return {Promise} - api response
     */
    @Action
    async login(user: Object): Promise<any> {
      this.__logout();
      mainStore.setStatus('loading');
      return new Promise((resolve, reject) => {
        axios({url: '/login', data: user, method: 'POST'})
            .then((resp) => {
              const token = resp.data.token;
              const expires = resp.data.expires;
              const curDate = new Date();
              curDate.setMinutes(curDate.getMinutes() + parseInt(expires));
              const expiredIn = curDate.getTime();

              localStorage.setItem('token', token);
              localStorage.setItem('expired_in', String(expiredIn));

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              const timeout = (parseInt(expires) - 5) * 60 * 1000;
              setTimeout(async () => {
                await this.refreshToken();
              }, timeout);
              this.__setTokenState(token);
              this.__setExpiredInState(String(expiredIn));
              userStore.getUserProfile();
              resolve(resp);
            })
            .catch((err) => {
              this.__logout();
              mainStore.setError();
              reject(err);
            });
      });
    }

    /**
     * Logout a user instance and clear session data
     * @return {Promise} - api response
     */
    @Action
    async logout(): Promise<any> {
      mainStore.setStatus('loading');
      return new Promise((resolve, reject) => {
        axios({url: '/logout', method: 'POST'})
            .then((resp) => {
              this.__logout();
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              this.__logout();
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Register a new user
     * @param {Object} user - registering user details
     * @return {Promise} - api response
     */
    @Action
    async register(user: Object): Promise<any> {
      return new Promise((resolve, reject) => {
        this.__logout();
        mainStore.setStatus('loading');
        axios({url: '/register', data: user, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Verify a users email via sent email
     * @param {string} code - email verification code from email link
     * @return {Promise} - api response
     */
    @Action
    async verifyEmail(code: string): Promise<any> {
      return new Promise((resolve, reject) => {
        this.__logout();
        mainStore.setStatus('loading');
        axios({url: `/email/verify/${code}`, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Resend a user's email verification email
     * @param {Object} payload - payload for email to be resent to user
     * @return {Promise} - api response
     */
    @Action
    async resendVerifyEmail(payload: Object): Promise<any> {
      return new Promise((resolve, reject) => {
        mainStore.setStatus('loading');
        axios({url: '/email/resend', data: payload, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Reset a user's password from their email address
     * @param {Object} payload - details for user to have password reset
     * @return {Promise} - api response
     */
    @Action
    async resetPassword(payload: Object): Promise<any> {
      return new Promise((resolve, reject) => {
        this.__logout();
        mainStore.setStatus('loading');
        axios({url: '/forgot-password', data: payload, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Change a users password
     * @param {any} payload - password change details
     * @return {Promise} - api response
     */
    @Action
    async changePassword(payload: any): Promise<any> {
      return new Promise((resolve, reject) => {
        mainStore.setLoading();
        const param = {
          password: payload.password,
          password_confirmation: payload.password_confirmation,
        };
        axios({url: `reset-password/${payload.code}`, data: param, method: 'POST'})
            .then((resp) => {
              mainStore.setSuccess();
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setError();
              reject(err);
            });
      });
    }

    /**
     * Validate whether invite code is a valid one or not before allowing user to register
     * @param {string} code - invite code to be validated
     * @return {Promise} - api response
     */
    @Action
    async validateInvite(code: string): Promise<any> {
      return new Promise((resolve, reject) => {
        mainStore.setStatus('loading');
        axios({url: `/validate-invite/${code}`, method: 'GET'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Register a user via invite
     * @param {any} payload - invite payload object including code and register details
     * @return {Promise} - api response
     */
    @Action
    async registerViaInvite(payload: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.__logout();
        mainStore.setStatus('loading');
        axios({url: `/register-via-invite/${payload.code}`, data: payload, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

    /**
     * Login as a user
     * @param {string} userId - user id login
     * @return {Promise} - api response
     */
    @Action
    async loginAs(userId: string): Promise<any> {
      this.__logout();
      mainStore.setStatus('loading');
      return new Promise((resolve, reject) => {
        axios({url: `/login-as/${userId}`, method: 'GET'})
            .then((resp) => {
              const token = resp.data.token;
              const expires = resp.data.expires;
              const curDate = new Date();
              curDate.setMinutes(curDate.getMinutes() + parseInt(expires));
              const expiredIn = curDate.getTime();

              localStorage.setItem('token', token);
              localStorage.setItem('expired_in', String(expiredIn));

              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              const timeout = (parseInt(expires) - 5) * 60 * 1000;
              setTimeout(async () => {
                await this.refreshToken();
              }, timeout);
              this.__setTokenState(token);
              this.__setExpiredInState(String(expiredIn));
              userStore.getUserProfile();
              resolve(resp);
            })
            .catch((err) => {
              this.__logout();
              mainStore.setError();
              reject(err);
            });
      });
    }

    /**
     * API to save device token
     * @param {Object} payload - save device token payload object including device_token and device type
     * @return {Promise} - api response
     */
     @Action
    async storeDeviceToken(payload: Object): Promise<any> {
      return new Promise((resolve, reject) => {
        mainStore.setStatus('loading');
        axios({url: '/storeDeviceToken', data: payload, method: 'POST'})
            .then((resp) => {
              mainStore.setStatus('success');
              resolve(resp);
            })
            .catch((err) => {
              mainStore.setStatus('error');
              reject(err);
            });
      });
    }

     /**
        *
        * @param {string} deviceToken - set device token
        */
              @Action
     async setDeviceToken(deviceToken: string) {
       this.__setDeviceToken(deviceToken);
     }

              // Getters
              /**
     *
     */
              get __GETTERisUserLoggedIn() {
                return !!this.token;
              }

              /**
   * Get state for deviceToken
   */
              get __GETTERdeviceToken() {
                return this.deviceToken;
              }
}

export const authStore = new AuthStore({store, name: 'authStore'});
